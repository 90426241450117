<template>
  <van-nav-bar
    :title="title"
  />
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:15px 0 0 0;">
        <p style="margin:0 0 15px 0;">
          <small>分润接收方 {{ item.memberName }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>状态 {{ item.statusText ?? '等待结算' }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>金额 &yen;{{ item.amount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>备注 {{ item.memo ?? '' }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>类型 {{ item.typeText ?? '销售分润' }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>时间 {{ item.createTime }}</small>
        </p>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      storeId: Cookies.get('storeId'),
      orderId: useRoute.query.orderId,
      userId: useRoute.query.userId,
      v1: 0,
      v2: 0,
      v3: 0,
      v4: 0
    })
    const getData = () => {
      post('/staffcommission.sum.details', {
        pageNum: state.page,
        storeId: state.storeId,
        userId: state.userId
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const sum = () => {
      // post('/order.sumOrder', {
      //   storeId: state.storeId,
      //   bizScope: 'DEPOSIT',
      //   status: 'COMPLETED'
      // }).then(res => {
      //   state.v1 = res.data.payAmount
      //   state.v2 = res.data.depositAmount
      //   state.v3 = res.data.depositGiftAmount
      // })
      // post('/member.sumDeposit', {
      //   storeId: state.storeId
      // }).then(res => {
      //   state.v4 = res.data.total
      // })
    }
    const init = () => {
      sum()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
